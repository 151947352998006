import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export const useSampleContentExperiment = () => {
	const getIsUserInTreatment = () => {
		const [expConfig] = UNSAFE_noExposureExp('cfind-2079_create_sample_content'); // Experiment exposure is fired in the backend
		const experimentVal = expConfig.get('cohort', 'control');

		return experimentVal === 'test';
	};

	return {
		isUserInTreatment: getIsUserInTreatment(),
	};
};
